import {React} from 'react';
import './Home.css';
import miragelogo from '../../assets/Core/Halolegion logo Trans 500by500.png';
import { useNavigate } from 'react-router-dom';
import { faInstagram,faYoutube,faTwitter} from '@fortawesome/free-brands-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { Timer } from '../../components/timer/timer';






const Home = () => {
  const navigate = useNavigate();
  return (
    
    <div className='home-main'>
      <div className="home-core">
          
        <div className='home-hero'>
          <div className='home-hero-logo'>
            
            {/* <img className="home-ellipse-img" alt="" src={ellipse} /> */}
            <img className="home-halolegion-logo" alt="" src={miragelogo} />

            <div className="home-hero-text">
              <h1 className="home-hero-halocon-text">
                <div className="home-hero-halocon-text-span"><span>H</span><span>A</span><span>L</span></div>  <div className="home-hero-halocon-text-span"><span>C</span><span>O</span><span>N</span></div>
              </h1>
            </div>
          </div>
          <h1 className="home-hero-halocon-text" id='home-hero-text2'>
            <span>H</span><span>A</span><span>L</span><span id='o-text'>O</span><span>C</span><span>O</span><span>N</span>
          </h1>
          
        </div>
        {/* <p className="home-info-text">Registrations Starts in !</p> */}
        <Timer className='home-countdown'/>
        <div className='home-bottom'>
            
            
            
            {/* <div className="home-copyright">
              <div className="homeicons">
                <a href='https://www.instagram.com/mirage.cgcj/'><FontAwesomeIcon className= "home-logos homelogo" icon={faInstagram} /> </a>
                <a href='https://www.youtube.com/channel/UCsBaH0ygQGMgOu5dExAw02A'><FontAwesomeIcon className= "home-logos homelogo" icon={faYoutube} /> </a>
                <a href='https://twitter.com/Mirage_CGCJ'><FontAwesomeIcon className= "home-logos homelogo" icon={faTwitter} /> </a>
                <a href='mailto:mirage@cgc.acm.org'><FontAwesomeIcon className= "home-logos homelogo" icon={faEnvelope} /> </a>
              </div>
            </div> */}
            
          <div className="home-button-container">
            {/* <button className="home-cssbuttons-io-button" onClick={() => { navigate("/about"); }}>About
              <div className="home-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path>
                </svg>
              </div>
            </button> */}
            <button className="home-cssbuttons-io-button " onClick={() => { navigate("/members"); }}>Our Team
              <div className="home-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"></path></svg>
              </div>
            </button>
          </div>
          
          
          
        </div>
      </div>
    </div>
  );
}

export default Home;
