import recoil from '../../assets/Core/recoil.png'
import qurious from '../../assets/Core/qurious.png'
import encode from '../../assets/Core/encode.png'
import monochrome from '../../assets/Core/monochrome.png'
import pixel from '../../assets/Core/pixel-perfect.png'
import ciniphilia from '../../assets/Core/cinephilia.png'

import recoil2 from '../../assets/Core/recoil2.png'
import qurious2 from '../../assets/Core/qurious2.png'
import encode2 from '../../assets/Core/encode2.png'
import monochrome2 from '../../assets/Core/monochrome2.png'
import pixel2 from '../../assets/Core/pixel-perfect2.png'
import ciniphilia2 from '../../assets/Core/cinephilia2.png'

export const Event_Data = [
    {
        name: "Mono chrome",
        desc:'Eligibility: Classes VIII-XII\n- Monochrome is not a team-based event.\n- Only a single student can participate from each school.\n- The topic will be revealed just before the commencement of the competition.\n- Participants shall carry their own cameras and any other required accessories (tripod, monopod, lenses, etc.).\n- The total time allotted for taking pictures will be 90 minutes.\n- The participants can submit only one final photograph.\n- Use of mobile phones for photography is strictly prohibited.\n- Participants also need to submit an EXIF (Exchangeable image file format) file of their submission.\n\nTeacher In-charge:\nMs. Linu Jain (+91 94253 45237)\n\nEvent Head:\nNarayani Malviya (+91 93434 99769)',
        icon: monochrome,
        icon2: monochrome2,
        btn: '/monochrome',
    },
    {
        name: "Pixel Perfect",
        desc:'Eligibility: Classes VIII-XII\n- The participants are required to create a poster on a given topic.\n- Adobe Photoshop and GIMP will be installed on the provided PCs.\n- Internet access will be provided.\n- The poster should be submitted in A4 (2480x3508px) size.\n- The image file of the poster (JPG/PNG) shall be submitted along with the native raw file.\n- The total time allotted for finishing the design is 90 minutes.\n\nJudgment Criteria:\n- Color Scheme\n- Typography\n- Element Positioning\n- Alignment and Sequencing\n- Visual Appeal\n\nTeacher In-charge:\nMs. Ragini Nagori (+91 79872 47780)\n\nEvent Head:\nGiriman Kohli (+91 99931 20311)',
        icon: pixel,
        icon2: pixel2,
        btn: '/pixelperfect',
    },
    {
        name: "Encode",
        desc: "Eligibility: Classes VIII-XII\n- Python will be the programming language tested.\n- Only one team can participate from each school.\n- The participating team must consist of two members.\n- This competition will consist of two rounds.\n- The total duration of the event is 130 minutes (60 minutes for each round + 10 minutes relaxation time).\n- The first round is designed to test the programming fundamentals and graphical skills of the participants.\n- During the second round, participants will have to solve logical problem statements with the help of Python code.\n- Any pre-written code or program is strictly prohibited and can lead to immediate disqualification.\n- Only IDLE Editor shall be used for programming.\n- Participants should be well equipped with the knowledge of file handling/Python connectivity/graphical module (PyGame module).\n\nTeacher In-charge:\n\nMs. Eti Joshi (+91 89894 60451)\n\nEvent Head:\n\nPrem Naik (+91 97556 07380)",
        icon: encode,
        icon2: encode2,
        btn: "/encode"
    },
    {
        name: "Qurious",
        desc:"Eligibility: Classes VI-XII\n- Qurious is a quizzing event which shall test the technological aptitude of the participants and their knowledge in the field of Information Technology.\n- Only one team can participate from each school.\n- The participating team must consist of two members.\n- The competition will consist of two rounds (Preliminary + Stage round).\n- The preliminary round will be a pen-paper round.\n- The top 5 teams in the preliminary round will advance to the stage round.\n- The format of the stage round will be disclosed on the day of the symposium itself.\n- The use of any device or software is strictly prohibited and can lead to immediate disqualification.\n- Any misbehavior (abuse, slang language, teasing, etc.) will lead to immediate disqualification.\n\nTeacher In-charge:\n\nMs. Shradha Koshti (+91 91797 80084)\n\nEvent Head:\n\nDiya Jaiswani (+91 74899 35573)",
        icon: qurious,
        icon2: qurious2,
        btn: '/qurious',
    },
    {
        name: "Recoil",
        desc:'Eligibility: Classes IX-XII\n- Valorant [Riot Games] would be the game played in the competition.\n- Only one team can participate from each school and each team must consist of 6 members (5 core + 1 substitute).\n- Each game will be played according to the standard rules of the game.\n- The brackets for the tournament will be revealed on the day of the event itself.\n- Any inappropriate behavior (including abusive language, teasing, etc.) will lead to disqualification.\n- Players can carry their own peripherals (keyboards, mouse, mic, headphones, etc.).\n- The matches will be played on the PCs provided by the school.\n- The players must log in with their respective Riot accounts on the PCs.\n- If any suspicious gameplay is observed, it will be reviewed and might lead to disqualification.\n\nTeacher In-charge:\nMs. Preeti Sapra (+91 94253 45237)\n\nEvent Head:\nViplav Meshram (+91 93409 49538)',
        icon: recoil,
        icon2: recoil2,
        btn: '/recoil',
    },
    
    {
        name: "Cinephilia",
        desc:'Eligibility: Classes IX-XII\n- The participants are required to create a short film on the given topic.\n- Adobe Premiere Pro, DaVinci Resolve, and Filmora Video Editor will be installed on the provided PCs.\n- Internet access will not be provided.\n- The film should be submitted in the aspect ratio 16:9 (1920x1080p).\n- The total time allotted for shooting and editing the film is 3 hours.\n- The participants are supposed to bring their own equipment (cameras, tripods, etc.).\n- The native file of the edited film is also required to be submitted.\n\nJudgment Criteria:\n- Cinematography\n- Colour Grading\n- Footage Quality\n- Direction\n- Story\n\nTeacher In-charge:\nMs. Sapna Bhargava (+91 79872 47780)\n\nEvent Head:\nAayush Ghadale (+91 87705 67298)',
        icon: ciniphilia,
        icon2: ciniphilia2,
        btn: '/cinephilia',
    },
    
    
    
];



