import React, { useEffect } from 'react';
import { Home,Devs,About,PersistLogin,Gallery,Privacy,Members,Events, Contact,Signin,Signup,Registration, Recoil,Qurious,Encode,Cinephilia,PixilPerfect,Monochrome,Dashboard ,Unauthorised,Pagenotfound,Profile,ForgotPass} from './Pages';
import Layout from './components/Layout';
import './App.css';
import { Routes, Route} from 'react-router-dom';
import RequireAuth from './hooks/RequireAuth';
import axios from './api/axios';
import useAuth from './hooks/useAuth';


export const ROLES = [
  'User',
  'Admin',
  'Developer',
  'Owner',
]


export const targetDate = new Date('2024-07-18T00:00:00').getTime();





const App = () => {
  const {auth} = useAuth();

  useEffect(()=>{
    console.log(auth)
  },[auth])


  const backendconnect = async () =>{try {
    const response = await axios.get('/connect',
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    console.log(response.data);
    
    } catch (err) {
      if (!err?.response) {
          console.log('No Server Response');
          console.log(err)
      } else {
          console.log('Registration Failed')
      }
    }
      }
  backendconnect();
  
  return (
    <>
    <Routes>
      <Route className = "Layout" path="/" element={<Layout />}>
      
        {/* public routes*/}
        
        
        
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/about' element={<About />} />
        <Route path='/unauthorised' element={<Unauthorised />} />
        <Route path='/pagenotfound' element={<Pagenotfound />} />
        {/* <Route path='/registration' element={<Registration />} /> */}
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/members' element={<Members />} />
        <Route path='/devs' element={<Devs />} />
        <Route path='/contact' element={<Contact />} /> 
        <Route path='/Events' element={<Events />} />
        <Route path='/recoil' element={<Recoil />} />
        <Route path='/encode' element={<Encode />} />
        <Route path='/qurious' element={<Qurious />} />
        <Route path='/monochrome' element={<Monochrome />} />
        <Route path='/cinephilia' element={<Cinephilia />} />
        <Route path='/pixelperfect' element={<PixilPerfect />} />
        <Route path='/qurious' element={<Qurious />} />
        <Route path='/gallery' element={<Gallery />} />
        
        
        <Route element={<PersistLogin/>}>
          <Route path='/signin' element={<Signin />} />
          <Route path='/forgotpass' element={<ForgotPass />} />
          <Route path='/profile' element={<Profile />} />
          
          <Route element={<RequireAuth allowedroles={["Admin"]}/>}>
            
            <Route path='/signup' element={<Signup />} />
            <Route path='/register' element={<Signup />} />
          </Route>
          <Route element={<RequireAuth allowedroles={["User"]}/>}>
            <Route path='/dashboard' element={<Dashboard />} />
          </Route>

        </Route>

        
        

        
        <Route path='*' element={<Pagenotfound />} />
        
      </Route>
    </Routes>
  </>
  
  )
}

export default App
