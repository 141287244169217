  import React from 'react';
import './Event-Page.css';
import "react-multi-carousel/lib/styles.css";
import { Timer } from '../../components/timer/timer';
import { Event_Data } from './eventsData';



const Event_Page = (data) => {
  return (
    <div className='ace-main'>
      <div className='ace-cards-row'>
        <div className='ace-horizontal-card'>
          <div className='ace-horizontal-card-left'>
            <img className="ace-horizontal-card-userimage" alt="" src={data.data.icon2}/>
          </div>
          <div className='ace-horizontal-card-right'>
            {/* <p>Click here to Register</p> */}
            <Timer className='home-countdown'/>
            {/* <Link to='/Registration'><button className="ace-event-button" type='button'>Register</button></Link> */}
          </div>
        </div>
      </div>
      {data.data.desc && (
      <div className="ace-bottom">
        <div className='ace-info'>
          <p>{data.data.desc}</p>
        </div>
        


      </div>
      )}

    </div >


  )
}



export const Monochrome = ()=>{
  return(
    <Event_Page data={Event_Data.filter(person => person.name === 'Mono chrome')[0]}/>
  )
}

export const Encode = ()=>{
  return(
    <Event_Page data={Event_Data.filter(person => person.name === 'Encode')[0]}/>
  )
}

export const Qurious = ()=>{
  return(
    <Event_Page data={Event_Data.filter(person => person.name === 'Qurious')[0]}/>
  )
}

export const PixilPerfect = ()=>{
  return(
    <Event_Page data={Event_Data.filter(person => person.name === 'Pixel Perfect')[0]}/>
  )
}

export const Recoil = ()=>{
  return(
    <Event_Page data={Event_Data.filter(person => person.name === 'Recoil')[0]}/>
  )
}



export const Cinephilia = ()=>{
  return(
    <Event_Page data={Event_Data.filter(person => person.name === 'Cinephilia')[0]}/>
  )
}






