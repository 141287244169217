import React from 'react';
import './Events.css';
import { Event_Data } from './eventsData';
import { Link } from 'react-router-dom';

const Events = () => {
    
    return (
        <div className='events-main'>
            <div className='events-core'>
                <div className='events-heading'>
                    <h1 className='events-heading-text'>Our Events</h1>
                </div>
                <div className='events-cards-row'>
                    {Event_Data.slice(2).map((card,index)=>(
                        <div className='events-vertical-card'>
                            <div className='events-vertical-card-top'>
                                <img className="events-vertical-card-userimage" src={card.icon} alt="Error in loading" />
                            </div>
                            <div className='events-vertical-card-bottom'>
                                <h1 className='events-vertical-name-text'>{card.name}</h1> 
                                
                                {/* <h2 className='events-vertical-info-text'><strong>{card.desc}</strong></h2> */}
                            </div>
                            <div className='events-buttons'>
                                <Link to={card.btn}><button className="events-button" type='button'>Learn More</button></Link>
                            </div>
                        </div>
                    ))}
                    
                </div>
                <div className='events-cards-row'>
                    {Event_Data.slice(0,2).map((card,index)=>(
                        <div className='events-vertical-card'>
                            <div className='events-vertical-card-top'>
                                <img className="events-vertical-card-userimage" src={card.icon} alt="Error in loading" />
                            </div>
                            <div className='events-vertical-card-bottom'>
                                <h1 className='events-vertical-name-text'>{card.name}</h1> 
                            </div>
                            <div className='events-buttons'>
                                <Link to={card.btn}><button className="events-button" type='button'>Learn More</button></Link>
                            </div>
                        </div>
                    ))}
                    
                </div>
                
            </div>
            
        </div>
        

    )
}

export default Events












