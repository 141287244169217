import React from 'react'
import './footer.css';
import { Menu } from '../Menu/Menu';
import { faInstagram,faYoutube,faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import inditech from '../../assets/Core/logo.png'



  
const Footer = () => {
  return (
    
      <div className="footer-main">
          <div className='footer-header-container'>
            <hr className='footer-hr' />
            <h1 className='footer-head-large'>INNOVATE TO INSPIRE</h1>
            <p className='footer-para'>Take action today and join us for an event experience like no other.</p>
            
          </div>

          <div className="footer-main-container">
            <hr className='footer-hr' />
            <div className='footer-core-container'>
              <div className='footer-navbar'>
                <Menu className='footer-menu-gap'/>
              </div>
              {/* <hr className='footer-hr' /> */}
              <div className="footericons">
                <a href='https://www.instagram.com/halolegion.official/'><FontAwesomeIcon className= "logos footerlogo" icon={faInstagram} /> </a>
                <a href='https://www.youtube.com/@halolegion'><FontAwesomeIcon className= "logos footerlogo" icon={faYoutube} /> </a>
                {/* <a href='https://twitter.com/Mirage_CGCJ'><FontAwesomeIcon className= "logos footerlogo" icon={faTwitter} /> </a> */}
                <a href='mailto:halocon@dpsindore.org'><FontAwesomeIcon className= "logos footerlogo" icon={faEnvelope} /> </a>
              </div>
            </div>
            <div className="footer-map-parent">
              <iframe
                title='gps'
                className='footer-map'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.082301305318!2d75.92851057601888!3d22.762326779357124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39631d65000691bd%3A0x2ca713146ed7bc3b!2sDelhi%20Public%20School%20Indore!5e0!3m2!1sen!2sin!4v1720934323794!5m2!1sen!2sin&zoom=20"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
              </iframe>
            </div>
          </div>

          <div className="footer-copyright">
            
            <div className='footer-bottom-text'>
              <a href='/privacy'><h3 className='footer-copyright-para'>All Rights Reserved By Halocon</h3></a>|
              <Link to="/devs"><h3 className='footer-copyright-para'>Developed By <img className='footer-inditech-logo' src={inditech} alt=''/></h3></Link>
            </div>
            <div className='footer-signin-logo-container'>
              <Link to="/signin"><FontAwesomeIcon className= "logos footersignin" icon={faEnvelope} /></Link>
            </div>
            
          </div>
      </div>
    
    
  )
}

export default Footer
