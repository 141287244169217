import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Dashboard.css";
import axios from "../../api/axios";
import { nav_card_Data } from "./DashboardData";
import {
  BarChartComponent,
  BarChartComponent2,
  DonutChartComponent,
} from "./ChartsData";
import {
  faCheckSquare,
  faCircleXmark,
  faUser,
  faPenToSquare,
  faCirclePlus,
  faCircleCheck,
  faUpRightFromSquare,
  faArrowLeft,
  faSort,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "../../hooks/useAuth";
import sample from "../../assets/Team images/sampleimg.png";
import { ROLES } from "../../App";

const Dashboard = () => {
  const { auth } = useAuth();

  const [home, setHome] = useState(true);
  const [playerspage, setPlayersPage] = useState(false);
  const [applicationspage, setApplicationsPage] = useState(false);
  const [userspage, setUsersPage] = useState(false);
  const [socialmediapage, setSocialMediaPage] = useState(false);
  const [analyticspage, setAnalyticsPage] = useState(false);
  const [infopage, setInfoPage] = useState(false);
  const [addnewrole, setAddNewRole] = useState(false);
  const [newrole, setNewRole] = useState("");
  const [sheetData, setSheetData] = useState([]);

  const [users, setUsers] = useState([]);
  const [infouser, setInfoUser] = useState([]);
  const [players, setPlayers] = useState([]);
  const [applications, setApplications] = useState([]);

  const [originalPlayers, setOriginalPlayers] = useState([]);
  const [ispeventSorted, setIsPEventSorted] = useState(false);
  const [ispschoolsorted, setIsPSchoolSorted] = useState(false);

  const [originalApplications, setOriginalApplications] = useState([]);
  const [isaeventSorted, setIsAEventSorted] = useState(false);
  const [isaschoolsorted, setIsASchoolSorted] = useState(false);

  const [isFieldsLocked, setIsFieldsLocked] = useState({
    users: [],
    players: [],
  });

  const USERS_URL = "/users";
  const PLAYERS_URL = "/players";
  const APPLICATION_URL = "/registration/application";

  const handleHome = () => {
    setHome(true);
    setUsersPage(false);
    setPlayersPage(false);
    setApplicationsPage(false);
    setSocialMediaPage(false);
    setAnalyticsPage(false);
    setInfoPage(false);
  };
  const handleSocialMedia = () => {
    setHome(false);
    setUsersPage(false);
    setPlayersPage(false);
    setSocialMediaPage(true);
    setAnalyticsPage(false);
    setInfoPage(false);
    setApplicationsPage(false);
  };
  const handlePlayers = () => {
    setHome(false);
    setUsersPage(false);
    setPlayersPage(true);
    setApplicationsPage(false);
    setSocialMediaPage(false);
    setAnalyticsPage(false);
    setInfoPage(false);
  };
  const handleUsersPage = () => {
    setHome(false);
    setPlayersPage(false);
    setUsersPage(true);
    setSocialMediaPage(false);
    setApplicationsPage(false);
    setAnalyticsPage(false);
    setInfoPage(false);
  };
  const handleAnalyticsPage = () => {
    setHome(false);
    setPlayersPage(false);
    setUsersPage(false);
    setSocialMediaPage(false);
    setApplicationsPage(false);
    setAnalyticsPage(true);
    setInfoPage(false);
  };
  const handleApplicationsPage = () => {
    setHome(false);
    setPlayersPage(false);
    setUsersPage(false);
    setSocialMediaPage(false);
    setApplicationsPage(true);
    setAnalyticsPage(false);
    setInfoPage(false);
  };
  const handleInfoPage = () => {
    setHome(false);
    setPlayersPage(false);
    setUsersPage(false);
    setSocialMediaPage(false);
    setAnalyticsPage(false);
    setApplicationsPage(false);
    setInfoPage(true);
  };
  const functionMapping = {
    home: handleHome,
    players: handlePlayers,
    users: handleUsersPage,
    social: handleSocialMedia,
    analytics: handleAnalyticsPage,
    applications: handleApplicationsPage,
  };

  const fetchUsersData = async () => {
    try {
      const response = await axios.get(USERS_URL);
      setUsers(response.data);
      setIsFieldsLocked((prev) => ({
        ...prev,
        users: Array(response.data.length).fill(true),
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const fetchPlayersData = async () => {
    try {
      const response = await axios.get(PLAYERS_URL);
      setOriginalPlayers(response.data);
      setPlayers(response.data);
      setIsFieldsLocked((prev) => ({
        ...prev,
        players: Array(response.data.length).fill(true),
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const fetchApplicationData = async () => {
    try {
      const response = await axios.get(APPLICATION_URL);
      console.log(response.data);
      setOriginalApplications(response.data);
      setApplications(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchApplicationData();
    fetchPlayersData();
    fetchUsersData();
  }, []);

  const handleToggleEditUser = (index) => {
    setIsFieldsLocked((prev) => {
      const newUserFieldsLocked = [...prev.users];
      newUserFieldsLocked[index] = !newUserFieldsLocked[index];
      return { ...prev, users: newUserFieldsLocked };
    });
  };

  const handleToggleEditPlayer = (index) => {
    setIsFieldsLocked((prev) => {
      const newPlayerFieldsLocked = [...prev.players];
      newPlayerFieldsLocked[index] = !newPlayerFieldsLocked[index];
      return { ...prev, players: newPlayerFieldsLocked };
    });
  };

  const handleverification = async (id) => {
    try {
      const response = await axios.get(`/register/verify?id=${id}`);
      setUsers((prevUsers) => {
        return prevUsers.map((user) => {
          if (user._id === id) {
            return { ...user, verified: true };
          }
          return user;
        });
      });
    } catch (err) {
      console.error(err);
    }
  };
  const handleRoleRemove = async (role, id) => {
    try {
      // Update the user roles on the front end
      setUsers((prevUsers) => {
        return prevUsers.map((user) => {
          if (user._id === id) {
            const newRoles = { ...user.roles };
            delete newRoles[role];
            return { ...user, roles: newRoles };
          }
          return user;
        });
      });

      await axios.patch("/users/roles/remove", { id, role });

      // console.log(`Removed role ${role} from user with ID ${id}`);
    } catch (err) {
      console.error(err);
    }
  };

  const handleNewRole = async (role, id) => {
    if (ROLES.includes(role)) {
      try {
        const response = await axios.put("/users/roles/add", { id, role });

        setUsers((prevUsers) => {
          return prevUsers.map((user) => {
            if (user._id === id) {
              const newRoles = { ...user.roles };
              newRoles[role] = true;
              // console.log(newRoles);
              setNewRole("");
              setAddNewRole(false);
              return { ...user, roles: newRoles };
            }
            return user;
          });
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      console.log("denied");
    }
  };

  const handleInfoPlayer = async (user, index) => {
    // console.log(user);
    // console.log(index);
    setInfoUser(user);
    handleInfoPage();
  };
  const handleSheetUpdate = async () => {
    try {
      const response = await axios.post(`/sheets`);
      // console.log(response.data);
      // Assuming you have a state variable called 'sheetData'
      setSheetData(response.data);
    } catch (error) {
      console.error("Error fetching sheet data:", error);
    }
  };

  // useEffect(() => {
  //   console.log(addnewrole);
  // }, [addnewrole]);

  // useEffect(() => {
  //   console.log(infouser);
  // }, [infouser]);

  // useEffect(() => {
  //   console.log(newrole);
  // }, [newrole]);

  // useEffect(() => {
  //   console.log(isFieldsLocked);
  // }, [isFieldsLocked]);

  const groupAndSortEvents = (players) => {
    const groupedEvents = {};

    players.forEach((player) => {
      const { event } = player;
      if (!groupedEvents[event]) {
        groupedEvents[event] = [];
      }
      groupedEvents[event].push(player);
    });

    return Object.keys(groupedEvents)
      .sort()
      .reduce((acc, event) => {
        acc.push(...groupedEvents[event]);
        return acc;
      }, []);
  };

  const groupAndSortSchool = (players) => {
    const groupedEvents = {};

    players.forEach((player) => {
      const { school } = player;
      if (!groupedEvents[school]) {
        groupedEvents[school] = [];
      }
      groupedEvents[school].push(player);
    });

    return Object.keys(groupedEvents)
      .sort()
      .reduce((acc, school) => {
        acc.push(...groupedEvents[school]);
        return acc;
      }, []);
  };

  const groupAndSortCollege = (players) => {
    const groupedEvents = {};

    players.forEach((player) => {
      const { college } = player;
      if (!groupedEvents[college]) {
        groupedEvents[college] = [];
      }
      groupedEvents[college].push(player);
    });

    return Object.keys(groupedEvents)
      .sort()
      .reduce((acc, college) => {
        acc.push(...groupedEvents[college]);
        return acc;
      }, []);
  };

  const toggleeventsort = (players) => {
    if (ispeventSorted) {
      setPlayers(originalPlayers);
      setIsPEventSorted(false);
    } else {
      const sortedplayers = groupAndSortEvents(players);
      setIsPEventSorted(true);
      setPlayers(sortedplayers);
    }
  };

  const toggleschoolsort = (players) => {
    // console.log("toggle run");
    if (ispschoolsorted) {
      setPlayers(originalPlayers);
      setIsPSchoolSorted(false);
    } else {
      const sortedplayers = groupAndSortSchool(players);
      setIsPSchoolSorted(true);
      setPlayers(sortedplayers);
    }
  };
  const toggleAeventsort = (players) => {
    if (isaeventSorted) {
      setApplications(originalApplications);
      setIsAEventSorted(false);
    } else {
      const sortedplayers = groupAndSortEvents(players);
      setIsAEventSorted(true);
      setApplications(sortedplayers);
    }
  };

  const togglecollegesort = (players) => {
    // console.log("toggle run");
    if (isaschoolsorted) {
      setApplications(originalApplications);
      setIsASchoolSorted(false);
    } else {
      const sortedplayers = groupAndSortCollege(players);
      setIsASchoolSorted(true);
      setApplications(sortedplayers);
    }
  };

  return (
    <div className="dashboard-main">
      <div className="dashboard-core">
        <div className="dashboard-nav-pane-main">
          <div className="dashboard-nav-card-group dashboard-nav-card-group-scroll">
            {nav_card_Data.map((cardData, index) => {
              const allowedRoles = cardData.roles.filter((role) =>
                Object.keys(auth.roles).includes(role)
              );
              if (allowedRoles.length > 0) {
                return allowedRoles.map((role, i) => (
                  <div
                    key={`${index}-${i}`}
                    className="dashboard-nav-card dashboard-nav-card-noglow"
                    onClick={functionMapping[cardData.btn]}
                  >
                    <FontAwesomeIcon
                      className="dashboard-nav-pane-logo"
                      icon={cardData.icon}
                    />
                    <h3 className="dashboard-nav-pane-text">{cardData.name}</h3>
                  </div>
                ));
              }
            })}
          </div>
          <hr className="dashboard-hr" />
          <div className="dashboard-nav-card-group dashboard-nav-footer-card-group">
            <Link
              className="dashboard-nav-card dashboard-nav-footer-card"
              to="/profile"
            >
              {auth.pfpUrl ? (
                <div className="dashboard-nav-pane-footer-pfp-container">
                  <img
                    className="dashboard-nav-pane-footer-pfp"
                    src={auth.pfpUrl}
                    alt=""
                  />
                </div>
              ) : (
                <FontAwesomeIcon
                  className="dashboard-nav-pane-logo dashboard-nav-footer-logo"
                  icon={faUser}
                />
              )}

              <h3 className="dashboard-nav-pane-text">My Profile</h3>
            </Link>
          </div>
        </div>
        <div className="dashboard-hero-pane-main">
          {home && (
            <>
              <h1>Welcome!</h1>
              <button
                className="home-cssbuttons-io-button"
                onClick={handleSheetUpdate}
              >
                UPDATE SHEET
              </button>
              <button
                className="home-cssbuttons-io-button"
                onClick={() =>
                  window.open(
                    "https://docs.google.com/spreadsheets/d/1sRRniZZuRHZKfZEunXbHZ9LSLDmeYWcIMn5IAh5Phzw/edit?usp=sharing"
                  )
                }
              >
                OPEN GOOGLE SHEET
              </button>

              <p>{sheetData}</p>
            </>
          )}
          {analyticspage && (
            <div className="dashboard-hero-pane-core">
              <div className="dashboard-hero-pane-home-container">
                <div className="dashboard-hero-pane-chart-card">
                  <h2>Total No. of Teams</h2>
                  <div className="dashboard-hero-pane-bar-chart-container">
                    <p className="dashboard-card-text">{applications.length}</p>
                  </div>
                </div>
                <div className="dashboard-hero-pane-chart-card">
                  <h2>Total No. of Participants</h2>
                  <div className="dashboard-hero-pane-bar-chart-container">
                    <p className="dashboard-card-text">{players.length}</p>
                  </div>
                </div>

                <div className="dashboard-hero-pane-chart-card">
                  {/* <h2>College Entries</h2> */}
                  <div className="dashboard-hero-pane-bar-chart-container">
                    <DonutChartComponent />
                  </div>
                </div>
              </div>
              <div className="dashboard-hero-pane-home-container">
                <div className="dashboard-hero-pane-chart-card">
                  <h2>Event Team Entries</h2>
                  <div className="dashboard-hero-pane-bar-chart-container">
                    <BarChartComponent />
                  </div>
                </div>
                <div className="dashboard-hero-pane-chart-card">
                  <h2>School Team Entries</h2>
                  <div className="dashboard-hero-pane-bar-chart-container">
                    <BarChartComponent2 />
                  </div>
                </div>
              </div>
            </div>
          )}
          {userspage && (
            <div className="dashboard-hero-pane-core">
              <h1>Users</h1>
              <div className="dashboard-hero-pane-table-container">
                <table className="dashboard-hero-pane-table">
                  <thead>
                    <tr>
                      <th className="dashboard-hero-pane-th" id="sno">
                        #
                      </th>
                      <th className="dashboard-hero-pane-th" id="uname">
                        Name
                      </th>
                      {/* <th className="dashboard-hero-pane-th" id="email">Email</th> */}
                      <th className="dashboard-hero-pane-th" id="role">
                        User Roles
                      </th>
                      <th className="dashboard-hero-pane-th" id="role">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={user._id}>
                        <td className="dashboard-user-card-td" id="sno">
                          <input
                            className="dashboard-user-checkbox"
                            type="checkbox"
                          />
                        </td>
                        <td className="dashboard-user-card-td">
                          <div className="dashboard-user-name-container">
                            <div className="dashboard-user-pfp-container">
                              <img
                                className="dashboard-user-card-pfp"
                                src={user.pfp || sample}
                                alt="pfp"
                              />
                            </div>
                            <div className="dashboard-user-card-name-container">
                              <p>{user.username}</p>
                              <p>{user.email}</p>
                            </div>
                            {!user.verified && (
                              <div className="dashboard-user-card-verify-container">
                                <div className="dashboard-roles-container">
                                  <div className="dashboard-verify-card">
                                    Not Verified
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="dashboard-user-card-td">
                          <div className="dashboard-roles-container">
                            {Object.keys(user.roles).map((role) => (
                              <div
                                className="dashboard-role-card"
                                key={role}
                                id={role}
                              >
                                {role}
                                {!isFieldsLocked.users[index] && (
                                  <FontAwesomeIcon
                                    className="dashboard-role-cross-icon"
                                    onClick={() =>
                                      handleRoleRemove(role, user._id)
                                    }
                                    icon={faCircleXmark}
                                  />
                                )}
                              </div>
                            ))}

                            {addnewrole && !isFieldsLocked.users[index] && (
                              <div className="dashboard-role-card">
                                <input
                                  className="dashboard-add-role-input"
                                  value={newrole}
                                  onChange={(event) => {
                                    setNewRole(event.target.value);
                                  }}
                                  type="text"
                                />
                              </div>
                            )}
                            {!isFieldsLocked.users[index] &&
                              (!addnewrole ? (
                                <FontAwesomeIcon
                                  className="dashboard-role-plus-icon"
                                  onClick={() => setAddNewRole(true)}
                                  icon={faCirclePlus}
                                />
                              ) : !newrole ? (
                                <FontAwesomeIcon
                                  className="dashboard-role-plus-icon"
                                  onClick={() => setAddNewRole(false)}
                                  icon={faCircleXmark}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="dashboard-role-plus-icon"
                                  onClick={() =>
                                    handleNewRole(newrole, user._id)
                                  }
                                  icon={faCircleCheck}
                                />
                              ))}
                          </div>
                        </td>
                        <td className="dashboard-user-card-td dashboard-user-card-td-actions">
                          {!user.verified && (
                            <div className="dashboard-user-card-verify-container">
                              <div className="dashboard-roles-container">
                                <div
                                  className="dashboard-verify-card"
                                  onClick={() => {
                                    handleverification(user._id);
                                  }}
                                >
                                  Verify
                                </div>
                              </div>
                            </div>
                          )}
                          {isFieldsLocked.users[index] ? (
                            <FontAwesomeIcon
                              className="dashboard-user-card-action-icon"
                              onClick={() => handleToggleEditUser(index)}
                              icon={faPenToSquare}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="dashboard-user-card-action-icon"
                              onClick={() => {
                                handleToggleEditUser(index);
                                setAddNewRole(false);
                              }}
                              icon={faCheckSquare}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {playerspage && (
            <div className="dashboard-hero-pane-core">
              <h1>Participants</h1>
              <div className="dashboard-hero-pane-table-container">
                <table className="dashboard-hero-pane-table">
                  <thead>
                    <tr>
                      <th className="dashboard-hero-pane-th" id="sno">
                        #
                      </th>
                      <th className="dashboard-hero-pane-th" id="uname">
                        Name
                      </th>
                      <th className="dashboard-hero-pane-th" id="tname">
                        Team Name
                      </th>
                      <th className="dashboard-hero-pane-th" id="sname">
                        <div>
                          School
                          {ispschoolsorted ? (
                            <FontAwesomeIcon
                              className="dashboard-user-card-action-icon dashboard-user-card-enlarge-icon"
                              onClick={() => toggleschoolsort(originalPlayers)}
                              icon={faSort}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="dashboard-user-card-action-icon dashboard-user-card-enlarge-icon"
                              onClick={() => toggleschoolsort(originalPlayers)}
                              icon={faFilter}
                            />
                          )}
                        </div>
                      </th>
                      <th className="dashboard-hero-pane-th" id="ename">
                        <div>
                          Event
                          {ispeventSorted ? (
                            <FontAwesomeIcon
                              className="dashboard-user-card-action-icon dashboard-user-card-enlarge-icon"
                              onClick={() => toggleeventsort(originalPlayers)}
                              icon={faSort}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="dashboard-user-card-action-icon dashboard-user-card-enlarge-icon"
                              onClick={() => toggleeventsort(originalPlayers)}
                              icon={faFilter}
                            />
                          )}
                        </div>
                      </th>
                      <th className="dashboard-hero-pane-th" id="contact">
                        Contact Number
                      </th>
                      {/* <th className="dashboard-hero-pane-th" id="role">Actions</th> */}
                    </tr>
                  </thead>
                  <tbody className="dashboard-user-tbody">
                    {players.map((user, index) => (
                      <tr key={user._id}>
                        <td className="dashboard-user-card-td" id="sno">
                          <input
                            className="dashboard-user-checkbox"
                            type="checkbox"
                          />
                        </td>
                        <td className="dashboard-user-card-td">
                          <div className="dashboard-user-name-container">
                            {/* <div className="dashboard-user-pfp-container">
                              <img className="dashboard-user-card-pfp" src={user.pfp} alt="Gov ID" />
                            </div> */}
                            <div className="dashboard-user-card-name-container">
                              <p>{user.name}</p>
                              <p>{user.email}</p>
                            </div>
                            {/* <FontAwesomeIcon
                                  className="dashboard-user-card-action-icon dashboard-user-card-enlarge-icon"
                                  onClick={() => handleInfoPlayer(user,index)}
                                  icon={faUpRightFromSquare}
                                /> */}
                          </div>
                        </td>
                        <td className="dashboard-user-card-td">
                          {user.team_name}
                        </td>
                        <td className="dashboard-user-card-td">
                          {user.school}
                        </td>
                        <td className="dashboard-user-card-td">{user.event}</td>
                        <td className="dashboard-user-card-td">
                          {user.contactNumber}
                        </td>
                        {/* <td className="dashboard-user-card-td dashboard-user-card-td-actions">
                          {isFieldsLocked.players[index] ? (
                            <FontAwesomeIcon
                              className="dashboard-user-card-action-icon"
                              onClick={() => handleToggleEditPlayer(index)}
                              icon={faPenToSquare}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="dashboard-user-card-action-icon"
                              onClick={() => handleToggleEditPlayer(index)}
                              icon={faCheckSquare}
                            />
                          )}
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {applicationspage && (
            <div className="dashboard-hero-pane-core">
              <h1>Applications</h1>
              <div className="dashboard-hero-pane-table-container">
                <table className="dashboard-hero-pane-table">
                  <thead>
                    <tr>
                      <th className="dashboard-hero-pane-th" id="sno">
                        #
                      </th>
                      <th className="dashboard-hero-pane-th" id="uname">
                        Teacher Name
                      </th>
                      <th className="dashboard-hero-pane-th" id="uname">
                        <div>
                          School Name
                          {isaschoolsorted ? (
                            <FontAwesomeIcon
                              className="dashboard-user-card-action-icon dashboard-user-card-enlarge-icon"
                              onClick={() =>
                                togglecollegesort(originalApplications)
                              }
                              icon={faSort}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="dashboard-user-card-action-icon dashboard-user-card-enlarge-icon"
                              onClick={() =>
                                togglecollegesort(originalApplications)
                              }
                              icon={faFilter}
                            />
                          )}
                        </div>
                      </th>
                      <th className="dashboard-hero-pane-th" id="uname">
                        <div>
                          Event Name
                          {isaeventSorted ? (
                            <FontAwesomeIcon
                              className="dashboard-user-card-action-icon dashboard-user-card-enlarge-icon"
                              onClick={() =>
                                toggleAeventsort(originalApplications)
                              }
                              icon={faSort}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="dashboard-user-card-action-icon dashboard-user-card-enlarge-icon"
                              onClick={() =>
                                toggleAeventsort(originalApplications)
                              }
                              icon={faFilter}
                            />
                          )}
                        </div>
                      </th>
                      <th className="dashboard-hero-pane-th" id="role">
                        Teacher Contact No.
                      </th>
                      {/* <th className="dashboard-hero-pane-th" id="role">Actions</th> */}
                    </tr>
                  </thead>
                  <tbody className="dashboard-user-tbody">
                    {applications.map((user, index) => (
                      <tr key={user._id}>
                        <td className="dashboard-user-card-td" id="sno">
                          <input
                            className="dashboard-user-checkbox"
                            type="checkbox"
                          />
                        </td>
                        <td className="dashboard-user-card-td">
                          <div className="dashboard-user-name-container">
                            {/* <div className="dashboard-user-pfp-container">
                              <img className="dashboard-user-card-pfp" src={user.pfp} alt="Gov ID" />
                            </div> */}
                            <div className="dashboard-user-card-name-container">
                              <p>{user.name}</p>
                              <p>{user.email}</p>
                            </div>
                          </div>
                        </td>
                        <td className="dashboard-user-card-td">
                          {user.college}
                        </td>
                        <td className="dashboard-user-card-td">{user.event}</td>
                        <td className="dashboard-user-card-td">
                          {user.contactNumber}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {infopage && infouser && (
            <div className="dashbord-info-main">
              <FontAwesomeIcon
                className="dashboard-user-card-action-icon dashboard-info-page-action-icon"
                icon={faArrowLeft}
                onClick={() => {
                  handlePlayers();
                  setInfoUser();
                }}
              />
              <div className="dashboard-info-card">
                <div className="dashboard-info-name-container">
                  <div className="dashboard-user-pfp-container">
                    <img
                      className="dashboard-info-user-card-pfp"
                      src={infouser.pfp}
                      alt="Gov ID"
                    />
                  </div>
                  <div className="dashboard-user-pfp-container">
                    <img
                      className="dashboard-info-user-card-idcard"
                      src={infouser.IdCard}
                      alt="Gov ID"
                    />
                  </div>
                </div>
              </div>

              <div className="dashboard-info-card2">
                <div className="dashboard-info-name-container2">
                  <div className="dashboard-user-card-name-container">
                    <p className="dashboard-info-card-text2">
                      Object ID: {infouser._id}
                    </p>
                    <p className="dashboard-info-card-text2">
                      Name: {infouser.name}
                    </p>
                    <p className="dashboard-info-card-text2">
                      Email: {infouser.email}
                    </p>
                    <p className="dashboard-info-card-text2">
                      College/University Roll Number: {infouser.rollno}
                    </p>
                    <p className="dashboard-info-card-text2">
                      Contact Number: {infouser.contactNumber}
                    </p>
                  </div>
                  <div className="dashboard-user-card-name-container">
                    <p className="dashboard-info-card-text2">
                      Team Name: {infouser.team_name}
                    </p>
                    <p className="dashboard-info-card-text2">
                      Event Name: {infouser.event}
                    </p>
                    <p className="dashboard-info-card-text2">
                      In Game ID: {infouser.inGameId}
                    </p>
                    <p className="dashboard-info-card-text2">
                      Seat Number: {infouser.seatname}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
