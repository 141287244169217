// membersData.js

import giriman from "../../assets/Team images/giriman-kohli.jpg";
import aarav from "../../assets/Team images/aarav-jain.jpg";
import amey from "../../assets/Team images/ameya-gangawati.jpg";
import arham from "../../assets/Team images/arham-saklecha.jpg";
import harshit from "../../assets/Team images/harshit-solanki.jpg";
import laabhit from "../../assets/Team images/laabhit-muddalwar.jpg";
import piyush from "../../assets/Team images/piyush-jha.jpg";
import nikunj from "../../assets/Team images/nikunj-ratre.jpg";
import prem from "../../assets/Team images/prem-naik.jpg";
import kritnu from "../../assets/Team images/kritnu-jatav.jpg";
import umar from "../../assets/Team images/umar-quazi.jpg";
import aayush from "../../assets/Team images/aayush-ghadale.jpg";
import amogh from "../../assets/Team images/amogh-yadav.jpg";

// import sample from "../../assets/Team images/sampleimg.png"
import recoil from "../../assets/Core/recoil.png";
import qurious from "../../assets/Core/qurious.png";
import encode from "../../assets/Core/encode.png";
import monochrome from "../../assets/Core/monochrome.png";
import pixel from "../../assets/Core/pixel-perfect.png";
import ciniphilia from "../../assets/Core/cinephilia.png";

import {
  faHtml5,
  faCss3Alt,
  faJs,
  faReact,
  faPython,
  faAws,
  faNodeJs,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import {
  faInstagram,
  faFacebook,
  faDiscord,
  faTwitter,
  faReddit,
  faAndroid,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("../../assets/Team images", false, /\.(png|jpe?g|JPG|webp)$/)
);

const imageMap = images.reduce((acc, image) => {
  const fullName = image
    .split("/")
    .pop()
    .split(".")[0]
    .toLowerCase()
    .split("-")[0];

  acc[fullName] = image;
  return acc;
}, {});

const memberDetails = [
  {
    name: "Giriman Kohli",
    role: "Club President",
    description: `Meet Giriman Kohli, our School and Cyber Club President. Giriman is an exceptional graphic designer, a fantastic leader, and a wonderful team worker. Known for his efficiency and dedication, he takes initiatives and always supports his team. With his fun and inspiring presence, he manages to balance top-notch academics and multiple responsibilities effortlessly.`,
    socialMedia: {
      instagram: "https://www.instagram.com/giriman.png/",
      linkedin: "https://www.linkedin.com/in/giriman-kohli-5b8aba283/",
      twitter: "https://x.com/GirimanKohli",
    },
    skills: [],
  },

  {
    name: "Aayush Ghadale",
    role: "Club Director",
    description:
      "Aayush Ghadale is not only the Head Boy of our school but also a remarkably creative and dynamic individual. With over five years of experience in editing, including specialization in 3D and filmmaking, Aayush has an extraordinary knack for bringing ideas to life on screen. Known for his curiosity and willingness to take initiative, Aayush is a true all-rounder whose artistic and academic talents shine brightly in every project he undertakes.",
    socialMedia: {
      instagram: "https://www.instagram.com/aaaxyush/",
      linkedin: "https://www.linkedin.com/in/aayush-ghadale-6b261122a/",
    },
    skills: [],
  },
  {
    name: "Viplav Meshram",
    role: "Event Head",
    event_name: "Recoil",
    description: "404 Not Found",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Laabhit Muddalwar",
    role: "Event Deputy Head",
    event_name: "Recoil",
    description:
      "Hi, I'm Laabhit, a student at DPS Indore with a strong passion for Coding and Gaming. I enjoy creating my own programs and playing  games, and have participated in several intra-school coding competitions. I've been involved in the school's Coding Club as a Core Team Member. Gaming not only entertains me but also sharpens my problem-solving abilities. I’m always enthusiastic about learning and exploring new opportunities to grow and contribute.",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Hardik Mehta",
    role: "Event Deputy Head",
    event_name: "Recoil",
    description: "404 Not Found",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Prem Naik",
    role: "Event Head",
    event_name: "Encode",
    description:
      "Prem Naik, a twelfth grader, is a dedicated programmer with four years of coding experience. Recently, he has been channeling his expertise into the exciting field of game development, focusing on C programming with OpenGL and Vulkan. Prem's commitment to mastering these advanced graphics technologies highlights his passion for pushing the boundaries of interactive entertainment.",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Piyush Jha",
    role: "Event Deputy Head",
    event_name: "Encode",
    description:
      "Piyush Jha, a driven twelfth grader and versatile developer, excels in both front-end and back-end development with 3-4 years of experience. Proficient in Python and Java, he crafts seamless user interfaces and efficient server-side logic, poised to make a significant impact in tech",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Amogh Yadav",
    role: "Event Deputy Head",
    event_name: "Encode",
    description:
      "Piyush Jha, a driven twelfth grader and versatile developer, excels in both front-end and back-end development with 3-4 years of experience. Proficient in Python and Java, he crafts seamless user interfaces and efficient server-side logic, poised to make a significant impact in tech",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Vishudh Singh Unhale",
    role: "Event Deputy Head",
    event_name: "Encode",
    description: "404 Not Found",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Diya Jaiswani",
    role: "Event Head",
    event_name: "Qurious",
    description: "404 Not Found",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Arham Saklecha",
    role: "Event Deputy Head",
    event_name: "Qurious",
    description: "404 Not Found",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Aarav Jain",
    role: "Event Deputy Head",
    event_name: "Qurious",
    description:
      "Aarav Jain, a tenth grader, is passionate about coding and technology. I enjoy developing software projects, learning new programming languages, and participating in coding competitions. With a keen interest in artificial intelligence and web development, I strive to create innovative solutions and foster connections within the tech community.",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Khyati Singh",
    role: "Secretary",
    event_name: "Event Coordinators",
    description:
      "Meet Khyati Singh, our Event Secretary for Halocon v.6. Khyati is incredibly versatile and excels in everything she takes on. A seasoned public speaker, she’s known for her dynamic presence and ability to engage any audience. Her leadership skills are top-notch, making her a key player in our team. Fun and energetic, Khyati gets the work done with impressive efficiency. Since joining the Computer Club in 2021, she has consistently shown her dedication and expertise. Khyati’s contributions are invaluable, making her a standout member of our school community.",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Kritnu Jatav",
    role: "Deputy Secretary",
    event_name: "Event Coordinators",
    description:
      "Kritnu Jatav will be serving as the Deputy Event Secretary for this year’s annual tech symposium. Known for his exceptional public speaking skills and oratory talent, Kritnu has garnered numerous accolades, particularly in tech-related presentation events. His remarkable ability to articulate complex ideas with clarity and confidence has set him apart, earning him significant recognition. Beyond his speaking prowess, Kritnu has demonstrated strong leadership in a variety of settings, further enhancing his ability to excel in diverse situations.",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Narayani Malviya",
    role: "Event Head",
    event_name: "Monochrome",
    description:
      "Narayani Malviya, a twelfth grader is a keen learner of photography, video graphics and cinematography. She has been seeing the world via lenses for quite some time, honing her skills a little more with each day",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Vihaan Mahajan",
    role: "Event Deputy Head",
    event_name: "Monochrome",
    description:
      "Vihaan Mahajan, a twelfth grader, is an avid photographer with five years of experience. He excels in editing with Lightroom, creating visually appealing images. His scientific, rule-based approach and in-depth knowledge set him apart. He stays updated on camera developments, constantly refining his skills.",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Nikunj Ratre",
    role: "Event Deputy Head",
    event_name: "Monochrome",
    description:
      "Nikunj Ratre, a ninth grader,is  a dedicated photographer specializing in capturing the beauty of everyday moments. With a focus on stunning landscapes and intimate portraits, my work aims to tell compelling stories through the lens.",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Aayush Gadhale",
    role: "Event Head",
    event_name: "Cinephilia",
    description:
      "Aayush Ghadale is not only the Head Boy of our school but also a remarkably creative and dynamic individual. With over five years of experience in editing, including specialization in 3D, Aayush has an extraordinary knack for bringing ideas to life on screen. His passion for filmmaking has culminated in the creation of an actual film, showcasing his talent and dedication to the craft. Known for his curiosity and willingness to take initiative, Aayush is a true all-rounder whose artistic and academic talents shine brightly in every project he undertakes.",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Harshit Solanki",
    role: "Event Deputy Head",
    event_name: "Cinephilia",
    description:
      "Harshit Solanki, a tenth grader, is a passionate video editor with a flair for both creativity and structure. He skillfully uses Premiere Pro and After Effects, blending methodical techniques with imaginative elements. Harshit thrives on taking on new challenges, which helps him continually refine his craft and stay at the forefront of editing innovation.",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Ameya Gangwati",
    role: "Event Deputy Head",
    event_name: "Cinephilia",
    description:
      "Ameya Gangawati, a tenth grader with over three years of editing experience, is skilled in using Sony Vegas and Premiere Pro. He has a talent for turning raw footage into visually engaging and polished videos.",
    socialMedia: {},
    skills: [],
  },
  {
    name: "Giriman Kohli",
    role: "Event Head",
    event_name: "Pixel Perfect",
    description:
      "Meet Giriman Kohli, our School and Cyber Club President. An exceptional graphic designer and leader, Giriman is known for his efficiency, dedication, and team spirit. He balances top-notch academics with multiple responsibilities, making him an indispensable part of our school community.",
    socialMedia: {},
    skills: ["HTML", "CSS", "JavaScript", "React"],
  },
  {
    name: "Umar Qazi",
    role: "Event Deputy Head",
    event_name: "Pixel Perfect",
    description:
      "Umar Quazi, a tenth grader, is a passionate graphic designer specializing in creating captivating visual art. With a focus on innovative designs and detailed illustrations, his work aims to communicate compelling stories through creativity.",
    socialMedia: {},
    skills: [],
  },
];

const membersData = memberDetails.map((member) => ({
  ...member,
  imageUrl: imageMap[member.name.split(" ")[0].toLowerCase()],
}));

export default membersData;

export const skillsData = [
  { name: "HTML", icon: faHtml5 },
  { name: "CSS", icon: faCss3Alt },
  { name: "JavaScript", icon: faJs },
  { name: "React", icon: faReact },
  { name: "Python", icon: faPython },
  { name: "Aws", icon: faAws },
  { name: "NodeJs", icon: faNodeJs },
  { name: "Android", icon: faAndroid },
];
export const socialMediaData = {
  facebook: faFacebook,
  twitter: faTwitter,
  instagram: faInstagram,
  discord: faDiscord,
  reddit: faReddit,
  linkedin: faLinkedin,
  github: faGithub,
  email: faEnvelope,
};
export const eventData = [
  {
    name: "Recoil",
    imageurl: recoil,
  },
  {
    name: "Encode",
    imageurl: encode,
  },
  {
    name: "Qurious",
    imageurl: qurious,
  },
  {
    name: "Monochrome",
    imageurl: monochrome,
  },
  {
    name: "Pixel Perfect",
    imageurl: pixel,
  },
  {
    name: "Cinephilia",
    imageurl: ciniphilia,
  },
  {
    name: "Event Coordinators",
    imageurl: ciniphilia,
  },
];
