import React from 'react';
import './About.css';

const Privacy = () => {
  return (
    <div className='privacy-main'>
      <div className='privacy-core'>
        <div className='privacy-box'>
          <div className='privacy-text-box'>
            <h1>GENERAL GUIDELINES</h1>
            <br/>
            <p>+ Halocon is an offline event, scheduled to be held on 10th August, 2024.</p>
            <p>+ Each school shall register through our website.</p>
            <p>+ Once registrations are complete, the participants and teachers in-charges will be added to an official WhatsApp group.</p>
            <p>+ Regular updates will be posted on our official Instagram Handle.</p>
            <p>+ The registrations are free of cost.</p>
            <p>+ Use of any artificial intelligence is strictly prohibited.</p>
            <p>+ The participants must strictly adhere to the respective event timings.</p>
            <p>+ No extra time will be allotted to the participants under any circumstances.</p>
            <p>+ The guidelines for various competitions have been specified in the invite.</p>
            <p>+ Results will be announced during the closing ceremony.</p>
            <p>+ For further details, kindly contact us.</p>
            <p>+ In case of any dispute, the decision taken by the organising committee would be deemed final.</p>
            <p>+ The last date for registration is 5th August, 2024.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
